define("isolocity/pods/settings/route", ["exports", "isolocity/config/environment", "isolocity/mixins/authentication-routing-mixin", "isolocity/mixins/unsaved-changes-handler-mixin", "isolocity/utils/datetime-formats"], function (_exports, _environment, _authenticationRoutingMixin, _unsavedChangesHandlerMixin, _datetimeFormats) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticationRoutingMixin.default, _unsavedChangesHandlerMixin.default, {
    authManager: Ember.inject.service('session'),
    modelName: 'organization',
    model: function model() {
      var session = this.get('authManager.session');
      return this.store.find('organization', session.get('currentUser.organization_id'));
    },
    afterModel: function afterModel(model, transition) {
      var session = this.get('authManager.session');
      model.set('currentUser', this.store.find('user', session.get('currentUser.id')));
      model.set('companies', this.store.findAll('company'));
      model.set('allRoles', this.store.findAll('role'));

      if (Ember.isBlank(model.get('okta_settings'))) {
        model.set('okta_settings', {
          issuerUrl: '',
          clientId: ''
        });
      }

      if (model) {
        // Use persisted formatted timestamp if available
        var persistedTimestamp = model.get('settings.last_azure_sync_formatted');

        if (persistedTimestamp) {
          model.set('localLastAzureSyncTimestamp', persistedTimestamp);
        } else {
          // Fall back to original logic only if no persisted value exists
          // Check if last_azure_sync_timestamp exists
          var lastSyncTimestamp = model.get('settings.last_azure_sync_timestamp');

          if (lastSyncTimestamp) {
            model.set('localLastAzureSyncTimestamp', (0, _datetimeFormats.default)(lastSyncTimestamp, this.get('authManager.session.currentUser.settings.timezone')));
          } else {
            model.set('localLastAzureSyncTimestamp', 'It has not synced before');
          }
        }
      } // Load Azure Active Directory settings


      $.ajax({
        headers: {
          Authorization: 'Bearer ' + this.get('session.session.content.authenticated.access_token'),
          ApiKey: _environment.default.APP.apiKey
        },
        url: _environment.default.APP.host + '/' + _environment.default.APP.namespace + '/azureOrganization',
        method: 'GET',
        data: []
      }).then(function (data) {
        console.log('data 11: ', data);

        if (data) {
          console.log('data 22: ', data);
          model.set('azureIntegration', data);
        }
      }).fail(function (data) {// Error here
      });
    }
  });

  _exports.default = _default;
});