define("isolocity/pods/components/model-tag-list/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    items: [],
    readOnlyItems: [],
    remainingItems: [],
    selectedItem: null,
    entityName: 'Item',
    isAvatarTag: false,
    isShowAvatarSelector: false,
    isPreventNew: true,
    redirectRoute: null,
    disabled: false,
    hideName: false,
    hideAddBtn: false,
    hideRemoveBtn: false,
    hidePublicId: false,
    readOnlyClass: 'readonly',
    addAction: 'toggleAvatarSelector',
    entityType: Ember.computed('entityName', function () {
      if (Ember.isEmpty(this.get('entityName'))) {
        return '';
      }

      if (this.get('entityName') == 'User') {
        return 'user';
      } else if (this.get('entityName') == 'Department') {
        return 'department';
      } else {
        return '';
      }
    }),
    useApi: Ember.computed('entityName', function () {
      return this.get('entityName') == 'User' || this.get('entityName') == 'Department';
    }),
    activeRemainingItems: Ember.computed('model.id', 'remainingItems', 'remainingItems.length', function () {
      if (Ember.isEmpty(this.get('remainingItems'))) {
        return [];
      }

      return this.get('remainingItems').filterBy('is_active', true);
    }),
    onSelectedItemChanged: Ember.observer('selectedItem', function () {
      if (!Ember.isBlank(this.get('selectedItem'))) {
        this.sendAction('onAddItemToList', this.get('selectedItem'));
        this.set('selectedItem', null);
        this.set('isShowAvatarSelector', false);
      }
    }),
    actions: {
      addItemToList: function addItemToList(item) {
        this.sendAction('onAddItemToList', item);
        this.set('isShowAvatarSelector', false);
      },
      removeItemFromList: function removeItemFromList(item) {
        this.sendAction('onRemoveItemFromList', item);
      },
      toggleAvatarSelector: function toggleAvatarSelector() {
        this.set('isShowAvatarSelector', !this.get('isShowAvatarSelector'));
      }
    }
  });

  _exports.default = _default;
});