define("isolocity/services/modules", ["exports", "isolocity/pods/equipment/index/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    modules: [],
    store: Ember.inject.service(),
    setModules: function setModules(modules) {
      this.set('modules', modules);
    },
    getModules: function getModules() {
      return this.get('modules');
    },
    getCurrentModule: function getCurrentModule() {
      var routeName = Ember.getOwner(this).lookup('controller:application').target.currentPath;
      routeName = routeName.replace('.index', '');
      routeName = routeName.replace('.new', '');
      routeName = routeName.replace('.edit', '');

      if (routeName.indexOf(".") !== -1) {
        routeName = routeName.substr(0, routeName.indexOf("."));
      }

      var cachedModules = Ember.getOwner(this).lookup('controller:application').model;

      if (!cachedModules) {
        cachedModules = this.get('store').peekAll('module');
      }

      var currentPath = Ember.getOwner(this).lookup('controller:application').target.currentPath;

      if (routeName === 'inspections') {
        if (currentPath === 'inspections.index') {
          var inspectionsModule = cachedModules.filterBy('ember_route', routeName).get('firstObject');
          return inspectionsModule || cachedModules.filterBy('ember_route', currentPath).get('firstObject');
        } else if (['inspections.activities', 'inspections.equipment', 'inspections.receiving'].includes(currentPath)) {
          // For specific child routes under 'inspections'
          return cachedModules.filterBy('ember_route', currentPath).get('firstObject');
        }
      } // Fallback for other routes


      return cachedModules.filterBy('ember_route', routeName).get('firstObject');
    },
    getModuleBySlug: function getModuleBySlug(slug) {
      var cachedModules = Ember.getOwner(this).lookup('controller:application').model;

      if (!cachedModules) {
        cachedModules = this.get('store').peekAll('module');
      }

      return cachedModules.filterBy('slug', slug).get('firstObject');
    },
    getModuleFieldLabel: function getModuleFieldLabel(slug, field, fallback) {
      var module = this.getModuleBySlug(slug);

      if (!Ember.isBlank(module) && !Ember.isBlank(module.get('fields.' + field + '.custom'))) {
        return module.get('fields.' + field + '.custom');
      }

      return fallback;
    },
    getModuleSingularFieldLabel: function getModuleSingularFieldLabel(slug, field, fallback) {
      var module = this.getModuleBySlug(slug);

      if (!Ember.isBlank(module)) {
        if (module.get('singular') != field) {
          return module.get('singular');
        }
      }

      return fallback;
    },
    getModulePluralFieldLabel: function getModulePluralFieldLabel(slug, field, fallback) {
      var module = this.getModuleBySlug(slug);

      if (!Ember.isBlank(module)) {
        if (module.get('plural') != field) {
          return module.get('plural');
        }
      }

      return fallback;
    },
    getModuleSetting: function getModuleSetting(slug, setting, fallback) {
      var module = this.getModuleBySlug(slug);

      if (!Ember.isBlank(module) && !Ember.isBlank(module.get('settings.' + setting))) {
        return module.get('settings.' + setting);
      }

      return fallback;
    },
    getModuleIcon: function getModuleIcon(slug) {
      var module = this.getModuleBySlug(slug);

      if (!Ember.isBlank(module) && !Ember.isBlank(module.get('icon'))) {
        return module.get('icon');
      }

      return '';
    }
  });

  _exports.default = _default;
});