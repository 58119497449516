define("isolocity/pods/ncrs/index/controller", ["exports", "isolocity/mixins/date-search-mixin", "isolocity/mixins/floating-sidebar-mixin", "isolocity/mixins/downloadable-reports-mixin", "isolocity/mixins/pagination-mixin", "isolocity/mixins/e-signature-mixin", "isolocity/mixins/ncr-part-a-feature-mixin"], function (_exports, _dateSearchMixin, _floatingSidebarMixin, _downloadableReportsMixin, _paginationMixin, _eSignatureMixin, _ncrPartAFeatureMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_eSignatureMixin.default, _dateSearchMixin.default, _floatingSidebarMixin.default, _downloadableReportsMixin.default, _paginationMixin.default, _ncrPartAFeatureMixin.default, {
    search: Ember.inject.service('search.ncrs'),
    searchStatus: null,
    searchNcrSource: null,
    searchErrorCode: null,
    searchOwner: null,
    searchLotNumber: null,
    moduleSlug: 'ncrs',
    getModules: function getModules() {
      return this.get('modules');
    },
    sortedModel: Ember.computed.sort('model.ncrs', 'sortProperties'),
    sortProperties: ['hasIncompleteActionItems:desc', 'has_unread_notes:desc', 'created_at:desc'],
    ncrOverviewOptions: ['By Part', 'By Error Code'],
    costOverviewOptions: ['Error Code', 'Part'],
    allReportData: Ember.computed('allStartDate', 'allEndDate', 'allStatus', 'allType', 'allSource', 'allHoldStatus', 'allNcrType', 'allPhaseType', 'allStandardStatus', function () {
      return {
        source: this.get('allSource') ? this.get('allSource') : null,
        hold_status: this.get('allHoldStatus') ? this.get('allHoldStatus.id') : null,
        ncr_type_id: this.get('allNcrType') ? this.get('allNcrType.id') : null,
        phase_type: this.get('allPhaseType') ? this.get('allPhaseType.id') : null,
        standard_status: this.get('allStandardStatus') ? this.get('allStandardStatus.id') : null,
        start_date: this.get('allStartDate') ? moment(this.get('allStartDate')).format("YYYY-MM-DD") : null,
        end_date: this.get('allEndDate') ? moment(this.get('allEndDate')).format("YYYY-MM-DD") : null
      };
    }),
    isEnableHoldStatus: Ember.computed(function () {
      var moduleFields = this.get('modules').getModuleSetting('ncrs', 'fields', false);

      if (moduleFields && moduleFields['visible']) {
        var visibleFields = moduleFields.visible;

        if (visibleFields.hold_status_field) {
          return true;
        }
      }

      return false;
    }),
    isEnableNcrType: Ember.computed(function () {
      var moduleFields = this.get('modules').getModuleSetting('ncrs', 'fields', false);

      if (moduleFields && moduleFields['visible']) {
        var visibleFields = moduleFields.visible;

        if (visibleFields.ncr_type_field) {
          return true;
        }
      }

      return false;
    }),
    isEnableStandardStatus: Ember.computed(function () {
      var moduleFields = this.get('modules').getModuleSetting('ncrs', 'fields', false);

      if (moduleFields && moduleFields['visible']) {
        var visibleFields = moduleFields.visible;

        if (visibleFields.standard_non_standard_field) {
          return true;
        }
      }

      return false;
    }),
    customReportData: Ember.computed('customStartDate', 'customEndDate', 'customSource', 'customSeverity', 'customDisposition', 'customPart', 'customErrorCode', 'holdStatus', 'customNcrType', 'phaseType', 'standardStatus', 'customSupplier', 'customCustomer', function () {
      if (this.get('customSource') !== 'Supplier') {
        this.set('customSupplier', null);
      }

      if (this.get('customSource') !== 'Customer') {
        this.set('customCustomer', null);
      }

      return {
        source: this.get('customSource') ? this.get('customSource') : null,
        severity: this.get('customSeverity') ? this.get('customSeverity') : null,
        disposition: this.get('customDisposition') ? this.get('customDisposition') : null,
        part_id: this.get('customPart') ? this.get('customPart.id') : null,
        error_code_id: this.get('customErrorCode') ? this.get('customErrorCode.id') : null,
        hold_status: this.get('holdStatus') ? this.get('holdStatus.id') : null,
        ncr_type_id: this.get('customNcrType') ? this.get('customNcrType.id') : null,
        phase_type: this.get('phaseType') ? this.get('phaseType.id') : null,
        standard_status: this.get('standardStatus') ? this.get('standardStatus.id') : null,
        customer_id: this.get('customCustomer') ? this.get('customCustomer.id') : null,
        supplier_id: this.get('customSupplier') ? this.get('customSupplier.id') : null,
        start_date: this.get('customStartDate') ? moment(this.get('customStartDate')).format("YYYY-MM-DD") : null,
        end_date: this.get('customEndDate') ? moment(this.get('customEndDate')).format("YYYY-MM-DD") : null
      };
    }),
    // For Historical Cost of Quality Graph
    costOfQualityCriteria: Ember.computed('selectedCostPart', 'selectedCostErrorCode', 'selectedCostCost', function () {
      return {
        part_id: !Ember.isEmpty(this.get('selectedCostPart')) ? this.get('selectedCostPart.id') : null,
        error_code_id: !Ember.isEmpty(this.get('selectedCostErrorCode')) ? this.get('selectedCostErrorCode.id') : null,
        cost: !Ember.isEmpty(this.get('selectedCostCost')) ? this.get('selectedCostCost') : null
      };
    }),
    actions: {
      addCustomSearchCriteria: function addCustomSearchCriteria() {
        this.get('search').setStatus(this.get('searchStatus'));
        this.get('search').setNcrSource(this.get('searchNcrSource'));
        this.get('search').setErrorCode(this.get('searchErrorCode.id'));
        this.get('search').setOwner(this.get('searchOwner.id'));
        this.get('search').setLotNumber(this.get('searchLotNumber'));
      },
      clearCustomSearchCriteria: function clearCustomSearchCriteria() {
        this.set('searchStatus', null);
        this.set('searchNcrSource', null);
        this.set('searchErrorCode', null);
        this.set('searchOwner', null);
        this.set('searchLotNumber', null);
      },
      // For Historical Cost of Quality Graph
      handleSelectedCostOverview: function handleSelectedCostOverview() {
        this.set('selectedCostPart', null);
        this.set('selectedCostErrorCode', null);
        this.set('selectedCostCost', null);
      },
      handleSelectedCostPart: function handleSelectedCostPart() {
        this.set('selectedCostOverview', null);
        this.set('selectedCostErrorCode', null);
        this.set('selectedCostCost', null);
      },
      handleSelectedCostErrorCode: function handleSelectedCostErrorCode() {
        this.set('selectedCostOverview', null);
        this.set('selectedCostPart', null);
        this.set('selectedCostCost', null);
      },
      handleSelectedCostCost: function handleSelectedCostCost() {
        this.set('selectedCostOverview', null);
        this.set('selectedCostPart', null);
        this.set('selectedCostErrorCode', null);
      }
    }
  });

  _exports.default = _default;
});