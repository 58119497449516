define("isolocity/pods/settings/controller", ["exports", "isolocity/config/environment", "isolocity/mixins/timezones-mixin", "isolocity/mixins/required-fields-mixin", "isolocity/utils/datetime-formats"], function (_exports, _environment, _timezonesMixin, _requiredFieldsMixin, _datetimeFormats) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_timezonesMixin.default, _requiredFieldsMixin.default, {
    intl: Ember.inject.service(),
    authManager: Ember.inject.service('session'),
    companyService: Ember.inject.service('company-switcher'),
    valuesChanged: Ember.observer('model.name', 'model.settings.order_number', function () {
      this.get('flashMessages').clearMessages();
    }),
    isLoading: false,
    isLoadingAzure: false,
    currencySymbols: ['$', '£', '€', 'R', 'RM', 'CHF', 'Mex$', 'Kr'],
    inspectionDisplayModes: [],
    setDefaultAzureSyncPermission: function setDefaultAzureSyncPermission() {
      var model = this.get('model');

      if (model && model.settings) {
        // Only set if it's undefined or null
        var azureSyncPermission = this.get('model.settings.is_sync_azure_users_and_groups');

        if (azureSyncPermission === undefined || azureSyncPermission === null) {
          this.set('model.settings.is_sync_azure_users_and_groups', true); // Default to true
        }
      } else {
        // If model.settings is not yet available, observe it and set the value once it's available
        this.addObserver('model.settings', this, this.setDefaultAzureSyncPermission);
      }
    },
    init: function init() {
      this.inspectionDisplayModes = [this.get('intl').t('settings.others.Standard'), this.get('intl').t('settings.others.Batch')], this._super.apply(this, arguments); // Ensure the model and settings are loaded before setting the default value

      this.setDefaultAzureSyncPermission();
    },
    actions: {
      /*
       // Hide unused lines if custom footer is disabled
       toggleCustomFooter() {
          const footer = document.getElementsByClassName('custom-footer');
          for (const section of footer) {
              if (section.classList.contains('hidden')) {
                  section.classList.remove('hidden');
              } else {
                  section.classList.add('hidden');
              }
          }
      },
       // Handlebars line
       @onToggle={{fn (action 'toggleCustomFooter')}}
       */
      save: function save() {
        var _this = this;

        var intl = this.get('intl');
        this.set('isLoading', true);
        this.get('model').save().then(function (settings) {
          if ($('img.preview-image').length > 0) {
            $('.component__settings_logo').toggle();

            _this.set('hasSaved', true);
          } else {
            _this.get('flashMessages').success(intl.t('.settings.others.The organization settings have been saved successfully'));
          }

          _this.set('isLoading', false);
        }, function (error) {
          _this.set('isLoading', false);

          _this.send('showServerErrors', error);
        });
      },
      onFileUploadSuccessfully: function onFileUploadSuccessfully(data) {
        var _this2 = this;

        var intl = this.get('intl');

        if (data.hasOwnProperty('file_id')) {
          this.get('model').set('logo', data.file_id);
          this.get('model').save().then(function (logo) {
            _this2.get('model').set('pic', _this2.store.find('file', data.file_id));

            _this2.get('flashMessages').success(intl.t('.settings.others.The image has been uploaded and organization settings have been saved successfully'));

            _this2.set('hasSaved', undefined);

            $('.component__settings_logo').toggle();
            var $el = $('#file-upload');
            $el.wrap('<form>').closest('form').get(0).reset();
            $el.unwrap();
          });
        }
      },
      onFileUploadFailure: function onFileUploadFailure(message) {
        this.get('flashMessages').danger(message);
        this.set('hasSaved', false);
      },
      toggleAzureActiveDirectory: function toggleAzureActiveDirectory() {
        var _this3 = this;

        var refresh = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
        var intl = this.get('intl');
        this.set('isLoadingAzure', true);
        var url = '';
        var method = '';
        var action = null; // Stop Users Sync

        if (!refresh && this.get('model.azureIntegration') && this.get('model.azureIntegration.is_active')) {
          action = 'deactivate';
          url = _environment.default.APP.host + '/' + _environment.default.APP.namespace + '/azureOrganization/destroy';
          method = 'DELETE';
        } // Inititate Users Sync
        else if (refresh || this.get('model.azureIntegration') && !this.get('model.azureIntegration.is_active')) {
            action = 'activate';
            url = _environment.default.APP.host + '/' + _environment.default.APP.namespace + '/azureOrganization/sync';
            method = 'GET';
          } // Authenticate with Azures
          else {
              action = 'authenticate';
              url = _environment.default.APP.host + '/' + _environment.default.APP.namespace + '/authenticate/azure';
              method = 'GET';
            }

        var session = this.get('authManager.session');
        $.ajax({
          headers: {
            Authorization: 'Bearer ' + session.get('content.authenticated.access_token'),
            ApiKey: _environment.default.APP.apiKey
          },
          url: url,
          method: method,
          data: {
            scopes: 'Users',
            is_sync_azure_users_and_groups: this.get('model.settings.is_sync_azure_users_and_groups')
          }
        }).then(function (response) {
          if (action == 'authenticate') {
            if (response.azureUathUrl) {
              _this3.get('authManager.session').set('azureSync', true);

              window.location = response.azureUathUrl;
            }
          } else if (action === 'deactivate') {
            // Success, sync has been deactivated
            _this3.set('model.azureIntegration.is_active', false);

            _this3.get('flashMessages').success('The users sync has been deactivated.');

            _this3.set('isLoadingAzure', false);
          } else if (action === 'activate') {
            var _response$data$organi, _response$data$organi2;

            // Success, reload view
            if (response.message === 'Successfully started syncing users from your Active Directory.') {
              _this3.set('model.azureIntegration', response.data);

              _this3.get('flashMessages').success('Successfully started syncing users from your Active Directory.');
            }

            _this3.set('isLoadingAzure', false);

            var localLastAzureSyncTimestamp = response === null || response === void 0 ? void 0 : (_response$data$organi = response.data.organization) === null || _response$data$organi === void 0 ? void 0 : (_response$data$organi2 = _response$data$organi.settings) === null || _response$data$organi2 === void 0 ? void 0 : _response$data$organi2.last_azure_sync_timestamp;

            if (localLastAzureSyncTimestamp) {
              var formattedTimestamp = (0, _datetimeFormats.default)(localLastAzureSyncTimestamp, _this3.get('authManager.session.currentUser.settings.timezone'));

              _this3.set('model.localLastAzureSyncTimestamp', formattedTimestamp); // Store in settings to persist the formatted value


              _this3.set('model.settings.last_azure_sync_formatted', formattedTimestamp);
            }
          }
        }).fail(function (error) {
          var errorMessages = error && error.responseJSON && error.responseJSON.message || 'An unknown error occurred';

          if (errorMessages === 'You are not authenticated, please initiate session with Microsoft Azure.') {// TO DO: Generate authUrl and redirect to Azure Log In
          }

          if (errorMessages === 'The Azure user has not consented to the permissions or these have expired.') {
            // Destroy AzureIntegration variables to restart authentication
            _this3.set('model.azureIntegration.is_active', null);

            _this3.set('model.azureIntegration', null);

            _this3.get('flashMessages').danger(intl.t('settings.others.The Azure users permissions have either expired or have not yet been consented to')); // Trigger restart authentication


            _this3.send('toggleAzureActiveDirectory');
          }

          _this3.set('isLoadingAzure', false);

          console.error("The azure authentication process failed due to " + errorMessages);
        });
      }
    }
  });

  _exports.default = _default;
});