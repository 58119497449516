define("isolocity/pods/drawing/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    identification: _emberData.default.attr('string'),
    revision: _emberData.default.attr('string'),
    revision_date: _emberData.default.attr('isodate'),
    is_latest_revision: _emberData.default.attr('boolean'),
    notes: _emberData.default.attr('string'),
    is_approved: _emberData.default.attr('boolean'),
    approved_by: _emberData.default.belongsTo('user'),
    approved_date: _emberData.default.attr('utc'),
    equipment_id: _emberData.default.belongsTo('equipment', {
      inverse: null
    }),
    part_id: _emberData.default.belongsTo('part', {
      inverse: null
    }),
    action_items: _emberData.default.hasMany('action-item'),
    incomplete_action_items_count: _emberData.default.attr('number'),
    unread_notes_count: _emberData.default.attr('number'),
    user_notes: _emberData.default.hasMany('note'),
    ccrs: _emberData.default.hasMany('ccr'),
    owners: _emberData.default.hasMany('user'),
    users: _emberData.default.hasMany('user'),
    activities: _emberData.default.hasMany('activity'),
    custom_labels: _emberData.default.attr(),
    revisions: _emberData.default.hasMany('drawing'),
    has_multiple_parts: _emberData.default.attr('boolean'),
    characteristics: _emberData.default.hasMany('drawing.characteristic', {
      inverse: 'drawing_id'
    }),
    categories: _emberData.default.hasMany('drawing.category'),
    files: _emberData.default.attr(),
    archived_at: _emberData.default.attr('utc'),
    // Front end only
    frequency_due: _emberData.default.attr(),
    // Computed
    is_equipment: Ember.computed(function () {
      return this.get('equipment_id.id') ? true : false;
    }),
    displayName: Ember.computed('name', 'revision', function () {
      if (Ember.isEmpty(this.get('revision'))) {
        return this.get('name');
      }

      return this.get('name');
    }),
    isForLabResult: Ember.computed('id', 'identification', function () {
      return this.get('identification') === 'Lab Result Criteria';
    })
  });

  _exports.default = _default;
});